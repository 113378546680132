import { Component, OnInit, Renderer2, ElementRef, ViewChild, HostListener } from '@angular/core';
declare var $: any;
// import { NavbarComponent } from 'ng-uikit-pro-standard';
import { Router, NavigationExtras, ActivatedRoute, Data } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    // $(window).scroll(function () {
    //   $('.navbar').toggleClass('scrolled', $(this).scrollTop() > 50);
    //   $('.navbar-brand').toggleClass('whitelogo', $(this).scrollTop() < 50);
    //   $('.navbar-brand').toggleClass('colorlogo', $(this).scrollTop() > 50);
    // });

    $(document).ready(function () {
      $(".custom-drop , dropdown-menu").hover(function () {
        $(".dropdown-menu").toggleClass("show");
      });
    });
  }

  async openService(num) {
    this.router.navigate(['/services/' + num]);
  }




}
