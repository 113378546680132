<section>

    <mdb-carousel [isControls]="true" class="carousel slide" [animation]="'fade'">
        <mdb-carousel-item>
            <div class="view w-100">
                <img src="assets//images/about/c2.jpg" alt="c1" height="350px" class="w-100">
                <div class="mask rgba-black-strong waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption flex-center">
                <div class="animate__animated animate__fadeInLeft">
                    <h1 class="h3-responsive">Adobe Named a Leader in B2B and B2C Commerce Suites by Independent
                        Research Firm</h1>
                    <p>May 19, 2020</p>
                    <div class="pt-3">
                        <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                            mdbWavesEffect>Learn
                            more</button>
                    </div>
                </div>
            </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
            <div class="view w-100">
                <img src="assets//images/about/c2.jpg" alt="c1" height="350px" class="w-100">
                <div class="mask rgba-black-strong waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption flex-center">
                <div class="animate__animated animate__fadeInLeft">
                    <h1 class="h3-responsive">Adobe Named a Leader in B2B and B2C Commerce Suites by Independent
                        Research Firm</h1>
                    <p>May 19, 2020</p>
                    <div class="pt-3">
                        <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                            mdbWavesEffect>Learn
                            more</button>
                    </div>
                </div>
            </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
            <div class="view w-100">
                <img src="assets//images/about/c1.jpg" alt="c1" class="w-100" height="350px">
                <div class="mask rgba-black-strong waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption flex-center">
                <div class="animate__animated animate__fadeInLeft">
                    <h1 class="h3-responsive">Adobe Named a Leader in Adobe Implementation Services by Independent
                        Research Firm</h1>
                    <p>May 19, 2020</p>
                    <div class="pt-3">
                        <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                            mdbWavesEffect>Learn
                            more</button>
                    </div>
                </div>
            </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
            <div class="view w-100">
                <img src="assets//images/about/c3.jpg" alt="c1" class="w-100" height="350px">
                <div class="mask rgba-black-strong waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption flex-center">
                <div class="animate__animated animate__fadeInLeft">
                    <h1 class="h3-responsive">Media Alert: Adobe Named a Leader in 2020 Gartner Magic Quadrant for
                        Multichannel Marketing Hubs</h1>
                    <p>May 19, 2020</p>
                    <div class="pt-3">
                        <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                            mdbWavesEffect>Learn
                            more</button>
                    </div>
                </div>
            </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
            <div class="view w-100">
                <img src="assets//images/about/c4.jpg" alt="c1" class="w-100" height="350px">
                <div class="mask rgba-black-strong waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption flex-center">
                <div class="animate__animated animate__fadeInLeft">
                    <h1 class="h3-responsive">Adobe Named a Leader in B2B and B2C Commerce Suites by Independent
                        Research Firm</h1>
                    <p>May 19, 2020</p>
                    <div class="pt-3">
                        <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                            mdbWavesEffect>Learn
                            more</button>
                    </div>
                </div>
            </div>
        </mdb-carousel-item>
    </mdb-carousel>

    <div class="row grey lighten-5 py-5">
        <div class="col-md-12">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 text-center">
                        <img src="assets/Icons/circular-clock.png" alt="no img" class="mb-3" width="85px">
                        <h4>trustworthy and reliable </h4>
                    </div>
                    <div class="col-md-2 text-center">
                        <img src="assets/Icons/circular-clock.png" alt="no img" class="mb-3" width="85px">
                        <h4>trustworthy reliable </h4>
                    </div>
                    <div class="col-md-2 text-center">
                        <img src="assets/Icons/circular-clock.png" alt="no img" class="mb-3" width="85px">
                        <h4>trustworthy and </h4>
                    </div>
                    <div class="col-md-2 text-center">
                        <img src="assets/Icons/circular-clock.png" alt="no img" class="mb-3" width="85px">
                        <h4> and reliable </h4>
                    </div>
                    <div class="col-md-2 text-center">
                        <img src="assets/Icons/circular-clock.png" alt="no img" class="mb-3" width="85px">
                        <h4>trustworthy and reliable </h4>
                    </div>
                    <div class="col-md-2 text-center">
                        <img src="assets/Icons/circular-clock.png" alt="no img" class="mb-3" width="85px">
                        <h4>trustworthy and reliable </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row py-5">
            <div class="col-md-6">
                <p>
                    <span class="h2"> Latest news &nbsp;</span>
                    <mdb-icon fas icon="rss" class="text-black-50" size="2x"></mdb-icon>

                </p>

                <p>
                    <strong> Adobe Named a Leader in Adobe Implementation Services by Independent Research Firm</strong>
                    Adobe Received the Top Scores in the Current Offering (Tied) and Market Presence Categories S...
                    June 25, 2020
                </p>
                <p>
                    <strong> Adobe Reports Record Revenue </strong>
                    Adobe (Nasdaq:ADBE) today reported financial results for its second quarter fiscal year 2020 ...
                    June 11, 2020
                </p>
                <p>
                    <strong> Adobe Named a Leader in Adobe Implementation Services by Independent Research Firm</strong>
                    Adobe Received the Top Scores in the Current Offering (Tied) and Market Presence Categories S...
                    June 25, 2020
                </p>
                <p>
                    <strong> Adobe Reports Record Revenue </strong>
                    Adobe (Nasdaq:ADBE) today reported financial results for its second quarter fiscal year 2020 ...
                    June 11, 2020
                </p>

                <p>
                    <strong> Adobe Named a Leader in Adobe Implementation Services by Independent Research Firm</strong>
                    Adobe Received the Top Scores in the Current Offering (Tied) and Market Presence Categories S...
                    June 25, 2020
                </p>
                <p>
                    <strong> Adobe Reports Record Revenue </strong>
                    Adobe (Nasdaq:ADBE) today reported financial results for its second quarter fiscal year 2020 ...
                    June 11, 2020
                </p>

            </div>
            <div class="col-md-6">
                <p>
                    <span class="h2"> Latest blog posts &nbsp;</span>
                    <mdb-icon fas icon="rss" class="text-black-50" size="2x"></mdb-icon>
                </p>
                <p>
                <p>
                    <strong> Adobe Named a Leader in Adobe Implementation Services by Independent Research Firm</strong>
                    Adobe Received the Top Scores in the Current Offering (Tied) and Market Presence Categories S...
                    June 25, 2020
                </p>
                <p>
                    <strong> Adobe Reports Record Revenue </strong>
                    Adobe (Nasdaq:ADBE) today reported financial results for its second quarter fiscal year 2020 ...
                    June 11, 2020
                </p>
                <p>
                    <strong> Adobe Named a Leader in Adobe Implementation Services by Independent Research Firm</strong>
                    Adobe Received the Top Scores in the Current Offering (Tied) and Market Presence Categories S...
                    June 25, 2020
                </p>
                <p>
                    <strong> Adobe Reports Record Revenue </strong>
                    Adobe (Nasdaq:ADBE) today reported financial results for its second quarter fiscal year 2020 ...
                    June 11, 2020
                </p>

                <p>
                    <strong> Adobe Named a Leader in Adobe Implementation Services by Independent Research Firm</strong>
                    Adobe Received the Top Scores in the Current Offering (Tied) and Market Presence Categories S...
                    June 25, 2020
                </p>
                <p>
                    <strong> Adobe Reports Record Revenue </strong>
                    Adobe (Nasdaq:ADBE) today reported financial results for its second quarter fiscal year 2020 ...
                    June 11, 2020
                </p>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="view white-text">
                <img src="assets//images/about/c5.jpg" alt="c1" class="w-100" height="300px">
                <div class="mask flex-center rgba-gray-strong">
                    <div class="text-center">
                        <p class="h2"> Social Media </p>
                        <br>
                        <!--Facebook-->
                        <a type="button" mdbBtn floating="true" size="lg" color="fb" class="waves-light" mdbWavesEffect>
                            <mdb-icon fab icon="facebook-f"></mdb-icon>
                        </a>

                        <!--Twitter-->
                        <a type="button" mdbBtn floating="true" size="lg" color="tw" class="waves-light" mdbWavesEffect>
                            <mdb-icon fab icon="twitter"></mdb-icon>
                        </a>


                        <!--Linkedin-->
                        <a type="button" mdbBtn floating="true" size="lg" color="li" class=" waves-light"
                            mdbWavesEffect>
                            <mdb-icon fab icon="linkedin-in"></mdb-icon>
                        </a>

                        <!--Instagram-->
                        <a type="button" mdbBtn floating="true" size="lg" color="ins" class="  waves-light"
                            mdbWavesEffect>
                            <mdb-icon fab icon="instagram"></mdb-icon>
                        </a>

                        <!--Dribbble-->
                        <a type="button" mdbBtn floating="true" size="lg" color="dribbble" class="  waves-light"
                            mdbWavesEffect>
                            <mdb-icon fab icon="dribbble"></mdb-icon>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>

</section>