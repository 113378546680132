<section class="scroll-x-0">
    <!-- <div class="mainNavbar"> -->
    <app-navbar></app-navbar>
    <!-- </div> -->

    <!-- <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark stylish-color sticky-top" class="subnavbar">
        <links class="mx-auto w-100">
            <a class="navbar-brand aboutsubrains" href="javascript:void(0)">Syberzenlabs</a>
            <ul class="navbar-nav mr-auto mt-lg-0">
                <li class="nav-item hoverUnderLine" routerLinkActive="nav-active">
                    <a class="nav-link" href="javascript:void(0)"> Our Products </a>
                </li>


            </ul>
        </links>
    </mdb-navbar> -->

    <div>
        <app-products-solutions-header></app-products-solutions-header>
    </div>


    <div class="black">
        <div class="container-fluid p-5 text-white">
            <div class="row">
                <div class="col-md-4 p-3 my-5">
                    <p class="fontsize70 h1 font-sm-h">Our Products</p>
                    <p class="h3 font-sm-p"> We make unexpected Possiblities</p>
                </div>
                <div class="col-md-4 p-3 my-5 contentCards">
                    <p class=" h-50 "> <img src="../../assets/images/techademiclog.png" width="130px" alt=""> </p>
                    <p class="h5 text-white-50">AI driven Learning Management solution with Socialised learning Features
                        to make students dream to learn and Mentor desire to teach. </p>
                </div>
                <div class="col-md-4 p-3 my-5 contentCards">
                    <p class="h-50"><img src="../../assets/images/codeschool.png" width="100px" alt=""> </p>
                    <p class="h5 text-white-50">Develop  your applications using Cloud IDE and get rid of high installation process and Development time. </p>
                </div>
                <div class="col-md-4 p-3 my-5 contentCards">
                    <p class="h-50"><img src="../../assets/images/translate.png" width="130px" alt=""></p>
                    <p class="h5 text-white-50 mb-5">Machine Translation Embedded in Video calls, Where different
                        group can be in same context with different languages.</p>
                </div>
                <div class="col-md-4 p-3 my-5 contentCards">
                    <p class="h-50"> <img src="../../assets/images/pokejobs.png" width="200px" alt=""></p>
                    <p class="h5 text-white-50 mb-5"> Recruitement Intelligence, which provide recruiters contextual
                        understading over data.</p>
                </div>
                <div class="col-md-4 p-3  my-5 contentCards">
                    <p class="h-50"><img src="../../assets/images/edge.png" width="150px" alt=""></p>
                    <p class="h5 text-white-50 mb-5">Your academic graph is destined to reach skyrocket growth.
                        Harnessing the most futuristic technology – AR, Syberzenlabs allows the students and the
                        academicians
                        to dive deeper into any discipline with enhanced clarity and understanding  the concepts</p>
                </div>
            </div>
        </div>
    </div>
 

    <div>
        <section class="elegant-color-dark">
            <div class="container-fluid myCarouselblock">
                <div class="row">
                    <div class="col-md-3 text-white py-sm-5">
                        <p class="fontsize70 h1 font-sm-h">Our Solutions</p>
                        <p class="h3 font-sm-p">Adopting the resilience, for better future.</p>
                    </div>
                    <div class="col-md-9">

                        <!-- <mdb-carousel [isControls]="true" class="carousel-multi-item multi-animation"
                            [type]="'carousel-multi-item'" [animation]="'slide'">
                            <mdb-carousel-item *ngFor="let item of slides; let i = index">
                                <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                                    <mdb-card class="my-1">
                                        <mdb-card-body class="p-0">
                                            <div class="p-4 carouselCards flex-center"
                                                [style.background-image]="card.img" (mouseover)="changeBackground(card)"
                                                (mouseout)="LeaveBackground(card)">
                                                <div class="text-center text-white">
                                                    <p class="h3 mb-4">{{card.title}}</p>
                                                    <p class="h5">{{card.description}}</p>
                                                </div>
                                            </div>
                                        </mdb-card-body>

                                    </mdb-card>
                                </div>
                            </mdb-carousel-item>
                        </mdb-carousel> -->
                        <div class="row">
                            <div class="col-md-4" *ngFor="let card of slides; let cardIndex = index">
                                <mdb-card class="my-1">
                                    <mdb-card-body class="p-0">
                                        <div class="p-4 carouselCards flex-center" [style.background-image]="card.img"
                                            (mouseover)="changeBackground(card)" (mouseout)="LeaveBackground(card)">
                                            <div class="text-center text-white">
                                                <p class="h3 mb-4">{{card.title}}</p>
                                                <p class="h5">{{card.description}}</p>
                                            </div>
                                        </div>
                                    </mdb-card-body>

                                </mdb-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="module pt-5 parallax-2 white" data-type="background" data-speed="10">
            <div class="row pt-5">
                <div class="col-md-6 pr-0">
                    <p class="h1 w-75  float-right font-sm-h py-sm-5">Transformative Experiences </p>
                    <div class="px-0 pt-4 mt-4 text-right">
                        <img src="/assets/images/bannersub.jpg" alt="no img" class="img-fluid pt-5" width="500px">
                    </div>
                </div>
                <div class="col-md-6 pl-0">
                    <p class="h5 p-sm-2">Purposeful innovation that builds resilience</p>
                    <p class="p-sm-2"> To sustain change, organisations need the right mindset, the right culture, and the right tools
                        and <br>methodologies. We transform the way our partners work at every level, so they can
                        innovate <br>purposefully, and keep on learning and growing.</p>
                    <img src="/assets/images/banner.jpg" alt="no img" class="img-fluid" width="100%">

                </div>
            </div>
        </section>


        <div>
            <div class="view" style="height: 600px;">
                <img src=" assets/images/contact.jpg" class="img-fluid d-block w-100 h-100" alt="smaple image">
                <div class="mask flex-center container">
                    <div class="text-center w-75">
                        <p class="h1 fontsize70 text-white font-sm-h">Let's co-create the future</p>
                        <p class="h5 mt-4 text-white font-sm-p">Join a long list of satisfied clients, partners, and successful
                            businesses
                            that we have had the pleasure of working with</p>
                        <div class="mt-4">
                            <button mdbBtn type="button" color="danger" class="btnWithHoverOnluborder" mdbWavesEffect
                                routerLink="/contactus">Get in Touch</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <div>
        <app-footer></app-footer>
    </div>
</section>