import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallaery',
  templateUrl: './gallaery.component.html',
  styleUrls: ['./gallaery.component.scss']
})
export class GallaeryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
