<section>
  <div class="ContactUsNavbar">
    <app-navbar></app-navbar>
  </div>
  <div class="container my-5 py-4">
    <section class="contact-section my-5 pt-5">
      <div class="card">
        <div>
          <div class="view" style="height: 600px;">
              <img src=" assets/images/contact.jpg" class="img-fluid d-block w-100 h-100" alt="smaple image">
              <div class="mask flex-center container">
                  <div class="text-center w-75">
                      <p class="h1 fontsize70 text-white font-sm-h">Let's co-create the future</p>
                      <p class="h5 mt-4 text-white">Join a long list of satisfied clients, partners, and successful
                          businesses that we have had the pleasure of working with</p>
                      <div class="mt-4">
                        <a class="btn btn-danger me-2" target="_blank" href="https://www.syberzen.com/contactus/" role="button">Connect With Us</a>
                      </div>
                  </div>
              </div>
          </div>

      </div>
      </div>
    </section>
  </div>

  <div>
    <app-footer></app-footer>
  </div>
</section>
