import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  usersCountIsExist = true;

  yearsOfTrust = 0;
  Projects = 0;
  Workshops = 0;
  Clients = 0;

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    let scrollHeight = window.pageYOffset;
     console.log(scrollHeight)

    if (scrollHeight >= 1600 && scrollHeight <= 1850) {
      if (this.usersCountIsExist) {
        this.yearsOfTrust = 3;
        this.Projects = 50;
        this.Workshops = 10;
        this.Clients = 50;
      }
    }
  }

  constructor() { }

  ngOnInit(): void {

    $(window).scroll(function () {
      $('.sideheaddingScroll').toggleClass('sideheadding-ShowUnderLine', $(this).scrollTop() > 750);
      $('.sideheaddingScrollrecent').toggleClass('sideheadding-ShowUnderLine', $(this).scrollTop() > 2100);
      $('.sideheaddingScroll2').toggleClass('sideheadding-ShowUnderLine', $(this).scrollTop() > 2900);
      $('.sideheaddingScroll3').toggleClass('sideheadding-ShowUnderLine', $(this).scrollTop() > 3080);

    });
  }

  Exploremore(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }

  // ngAfterContentInit() {
  //   this.VideoContainer.nativeElement.play();
  // }

}
