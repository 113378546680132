<section class="scroll-x-0">
    <div>
        <app-navbar></app-navbar>
    </div>

    <div class="myheader mt-4">
        <div class="container h-100">
            <div class="row headerContent h-100 mx-0">
                <div class="col-md-7 flex-center">
                    <div class="text-white mt-5 pt-5">
                        <p class="h1 header mt-6 mb-3 pt-4 animate__animated animate__fadeInUp animate__slow">Creating
                            Resilient
                            Future with Intelligence </p>
                        <p class="headderContent h4 mb-5 animate__animated animate__fadeInUp animate__delay-2s">There’s
                            a
                            time
                            when words alone are not enough,
                            and
                            that time is now, We bring together
                            strategy, Intelligent design, engineering and data to help organisations become resilient.
                        </p>

                        <div class="animate__animated animate__fadeInUp animate__delay-2s animate__slower">
                            <button mdbBtn type="button" class="px-5" size="lg" color="danger"
                                (click)="Exploremore(services)" mdbWavesEffect>Explore
                                more</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="underheaderCcntent">
            <div class="row">
                <div class="col-md-4 px-0">
                    <div class="view overlay zoom UnderheaderSets">
                        <img src="../../assets/images/landingser1.jpg" class="img-fluid w-100 h-100" alt="smaple image">
                        <div class="mask text-white">
                            <div class="w-100 overlaymaskContent">
                                <div class="row">
                                    <div class="col-md-12 px-5">
                                        <h2 class="sideheaddingsBorder">SERVICES</h2>
                                        <p class="h2 font-weight-bold">Deliver platforms with data and Intelligence at
                                            the core.</p>

                                        <div>
                                            <a mdbBtn href="services/5" class="px-5 " size="sm" role="button"
                                                color="danger" mdbWavesEffect>READ MORE</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 px-0">
                    <div class="view overlay zoom UnderheaderSets">
                        <img src="../../assets/images/landingser2.jpg" class="img-fluid w-100 h-100" alt="smaple image">
                        <div class="mask text-white">
                            <div class="w-100 overlaymaskContent">
                                <div class="row">
                                    <div class="col-md-12 px-5">
                                        <h2 class="sideheaddingsBorder">RESILIENCE</h2>
                                        <p class="h2 font-weight-bold">India's School on Cloud, offers viable
                                            educational Solution.
                                        </p>

                                        <div>
                                            <a mdbBtn href="products-solutions" class="px-5 " size="sm" role="button"
                                                color="danger" mdbWavesEffect>READ MORE</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 px-0">
                    <div class="view overlay zoom UnderheaderSets">
                        <img src="../../assets/images/landingser3.jpg" class="img-fluid w-100 h-100" alt="smaple image">
                        <div class="mask text-white">
                            <div class="w-100 overlaymaskContent">
                                <div class="row">
                                    <div class="col-md-12 px-5">
                                        <h2 class="sideheaddingsBorder">RESEARCH</h2>
                                        <p class="h2 font-weight-bold"> Automated Learning Conversational AI.</p>

                                        <div>
                                            <a mdbBtn href="services/5" class="px-5 " size="sm" role="button"
                                                color="danger" mdbWavesEffect>READ MORE</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div #services>
        <div class="serviceContainer HomeFeatures py-5 py-sm-0">
            <div class="container ">
                <div class="row">
                    <!-- <div class="col-md-4">
                    <p class="h3 my-5">
                        <span class="sideheadding sideheaddingScroll">Our Services</span> </p>
                    <p class="h1"> A seamless <br> combination of <br> Intelligence, design, <br> engineering and data
                    </p>
                </div> -->


                    <div class="col-md-12 my-5">
                        <div class="row">
                            <div class="col-md-4">
                                <p class="h1 my-5 serviceheadding">
                                    <span class="sideheadding sideheaddingScroll">Our Services</span> </p>
                                <p class="h2"> A seamless <br> combination of <br> Intelligence, design, <br>
                                    engineering
                                    and data.
                                </p>

                            </div>
                            <div class="col-md-4 my-3">
                                <mdb-card class="servicesCards">
                                    <mdb-card-img src="assets/images/analytics.jpg" alt="Card image cap"
                                        class="serviceimg1"></mdb-card-img>
                                    <mdb-card-img src="assets/images/sample.jpg" alt="Card image cap"
                                        class="serviceimg2">
                                    </mdb-card-img>
                                    <mdb-card-body class="black text-white border-bottom-radius ">
                                        <h2 class="servicesCardsTitle">
                                            <span> Digital Transformation</span>
                                        </h2>
                                        <mdb-card-text class="mt-3 text-white">

                                            Syberzen Labs provides accurate unprejudiced research performance analysis
                                            by
                                            combining our high-quality data sources with technology and Innovation.
                                        </mdb-card-text>
                                        <a href="services/1" class="stretched-link"></a>
                                    </mdb-card-body>
                                </mdb-card>
                            </div>

                            <div class="col-md-4 my-3">
                                <mdb-card class="servicesCards">
                                    <mdb-card-img src="assets/images/designthinkingblack.jpg" alt="Card image cap"
                                        class="serviceimg1">
                                    </mdb-card-img>
                                    <mdb-card-img src="assets/images/designthinking.png" alt="Card image cap"
                                        class="serviceimg2">
                                    </mdb-card-img>
                                    <mdb-card-body class="black text-white border-bottom-radius ">
                                        <h2 class="servicesCardsTitle">
                                            <span> Design Thinking </span>
                                        </h2>
                                        <mdb-card-text class="mt-3 text-white">
                                            Design thinking is a human-centered approach to innovation that integrates
                                            the
                                            needs of people, the possibilities of technology, and the requirements for
                                            business success.
                                        </mdb-card-text>
                                        <a href="services/2" class="stretched-link"></a>
                                    </mdb-card-body>
                                </mdb-card>
                            </div>
                            <div class="col-md-4 my-3"></div>
                            <!-- <div class="col-md-4 my-3">
                            <mdb-card class="servicesCards">
                                <mdb-card-img src="assets/images/testingblack.jpg" alt="Card image cap"
                                    class="serviceimg1"></mdb-card-img>
                                <mdb-card-img src="assets/images/testing.jpg" alt="Card image cap" class="serviceimg2">
                                </mdb-card-img>
                                <mdb-card-body class="black text-white border-bottom-radius ">
                                    <h2 class="servicesCardsTitle"><span> Intelligent Test Automation</span></h2>
                                    <mdb-card-text class="mt-3 text-white"> Our Intelligent Testing services are
                                        designed to optimize every aspect of your testing lifecycle and deliver big
                                        business benefits from discovery all the way through maintenance. content.
                                    </mdb-card-text>
                                    <a href="services/3" class="stretched-link"></a>
                                </mdb-card-body>
                            </mdb-card>
                        </div> -->
                            <div class="col-md-4 my-3">
                                <mdb-card class="servicesCards">
                                    <mdb-card-img src="assets/images/weblack.jpg" alt="Card image cap"
                                        class="serviceimg1">
                                    </mdb-card-img>
                                    <mdb-card-img src="assets/images/web.jpg" alt="Card image cap" class="serviceimg2">
                                    </mdb-card-img>
                                    <mdb-card-body class="black text-white border-bottom-radius ">
                                        <h2 class="servicesCardsTitle"><span> Product Engineering and Beyond </span>
                                        </h2>
                                        <mdb-card-text class="mt-3 text-white">We provide next-generation digital
                                            applications and services to help you reach your strategic goals.
                                        </mdb-card-text>
                                        <a href="services/4" class="stretched-link"></a>
                                    </mdb-card-body>
                                </mdb-card>
                            </div>
                            <div class="col-md-4 my-3">
                                <mdb-card class="servicesCards">
                                    <mdb-card-img src="assets/images/linguisticblack.jpg" alt="Card image cap"
                                        class="serviceimg1">
                                    </mdb-card-img>
                                    <mdb-card-img src="assets/images/linguistic.jpg" alt="Card image cap"
                                        class="serviceimg2">
                                    </mdb-card-img>
                                    <mdb-card-body class="black text-white border-bottom-radius ">
                                        <h2 class="servicesCardsTitle"><span> Artificial Intelligence</span></h2>
                                        <mdb-card-text class="mt-3 text-white"> Conversational AI is changing the way we
                                            engage with customers vastly improving reach, responsiveness and
                                            personalization.
                                        </mdb-card-text>
                                        <a href="services/5" class="stretched-link"></a>
                                    </mdb-card-body>
                                </mdb-card>
                            </div>
                            <!-- <div class="col-md-4 my-3">
                            <mdb-card class="servicesCards">
                                <mdb-card-img src="assets/images/mobie.jpg" alt="Card image cap" class="serviceimg1">
                                </mdb-card-img>
                                <mdb-card-img src="assets/images/mobiles.jpg" alt="Card image cap" class="serviceimg2">
                                </mdb-card-img>
                                <mdb-card-body class="black text-white border-bottom-radius ">
                                    <h2 class="servicesCardsTitle"><span> Design Topology </span></h2>
                                    <mdb-card-text class="mt-3 text-white"> SuBrains has extensive, hands-on
                                        experience with the leading visual design tools on the market. We can help you
                                        take full advantage of these tools to maximize your ROI with them.
                                    </mdb-card-text>
                                </mdb-card-body>
                            </mdb-card>
                        </div> -->


                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="container-fluid grey lighten-3">
            <div class="row py-4">

                <div class="col-md-3 py-4">
                    <p class="h1 whatwehave text-center"> <span [countUp]="yearsOfTrust">0</span> </p>
                    <p class="text-center h3 cyan-text">years of Trust</p>
                </div>
                <div class="col-md-3 py-4">
                    <p class="h1 whatwehave text-center"><span [countUp]="Projects">0</span>+ </p>
                    <p class="text-center h3 cyan-text">Projects</p>
                </div>
                <div class="col-md-3 py-4">
                    <p class="h1 whatwehave text-center"><span [countUp]="Workshops">0</span>+</p>
                    <p class="text-center h3 cyan-text">Products & Solutions</p>
                </div>
                <div class="col-md-3 py-4">
                    <p class="h1 whatwehave text-center"><span [countUp]="Clients">0</span>+</p>
                    <p class="text-center h3 cyan-text">Clients</p>
                </div>

            </div>
        </div>
    </div>


    <section>
        <div class="black py-4">
            <p class="h1 text-white ml-5 pl-5 recentwork">
                <span class="sideheadding sideheaddingScrollrecent"> Recent Work </span></p>
        </div>
        <div>
            <div class="row">
                <div class="col-md-4 px-0">
                    <div class="view overlay zoom">
                        <img src="../../assets/images/work1.jpg" class="img-fluid w-100 h-100" alt="smaple image">
                        <div class="mask text-white">
                            <div class="w-100 overlaymaskContent">
                                <div class="row">
                                    <div class="col-md-12 px-5">
                                        <h2 class="sideheaddingsBorder">SERVICES</h2>
                                        <p class="h3 font-weight-bold">Aquaculture with Internet of Things and AI
                                        </p>

                                        <!-- <div>
                                            <button mdbBtn type="button" class="px-5 " size="sm" color="danger"
                                                mdbWavesEffect>READ MORE</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 px-0">
                    <div class="view overlay zoom">
                        <img src="../../assets/images/work2.jpg" class="img-fluid w-100 h-100" alt="smaple image">
                        <div class="mask text-white">
                            <div class="w-100 overlaymaskContent">
                                <div class="row">
                                    <div class="col-md-12 px-5">
                                        <h2 class="sideheaddingsBorder">RESILIENCE</h2>
                                        <p class="h3 font-weight-bold">Concurrent Collaborative Cloud IDE, which makes
                                            fast the Development.
                                        </p>

                                        <!-- <div>
                                            <button mdbBtn type="button" class="px-5 " size="sm" color="danger"
                                                mdbWavesEffect>READ MORE</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 px-0">
                    <div class="view overlay zoom">
                        <img src="../../assets/images/work3.jpg" class="img-fluid w-100 h-100" alt="smaple image">
                        <div class="mask text-white">
                            <div class="w-100 overlaymaskContent">
                                <div class="row">
                                    <div class="col-md-12 px-5">
                                        <h2 class="sideheaddingsBorder">SERVICES</h2>
                                        <p class="h3 font-weight-bold"> Rethinking Industry, with applied Computer
                                            Vision.</p>

                                        <!-- <div>
                                            <button mdbBtn type="button" class="px-5 " size="sm" color="danger"
                                                mdbWavesEffect>READ MORE</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0">
                    <div class="view overlay zoom">
                        <img src="../../assets/images/work4.jpg" class="img-fluid w-100 h-100" alt="smaple image">
                        <div class="mask text-white">
                            <div class="w-100 overlaymaskContent">
                                <div class="row">
                                    <div class="col-md-12 px-5">
                                        <h2 class="sideheaddingsBorder">RESILIENCE</h2>
                                        <p class="h3 font-weight-bold">Gaming Services with advanced technology.
                                        </p>

                                        <!-- <div>
                                            <button mdbBtn type="button" class="px-5 " size="sm" color="danger"
                                                mdbWavesEffect>READ MORE</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 px-0">
                    <div class="view overlay zoom">
                        <img src="../../assets/images/work5.jpg" class="img-fluid w-100 h-100" alt="smaple image">
                        <div class="mask text-white">
                            <div class="w-100 overlaymaskContent">
                                <div class="row">
                                    <div class="col-md-12 px-5">
                                        <h2 class="sideheaddingsBorder">SUNNY the Bot </h2>
                                        <p class="h3 font-weight-bold"> Rethinking Conversational AI, Bot That Learns.
                                        </p>

                                        <!-- <div>
                                            <button mdbBtn type="button" class="px-5 " size="sm" color="danger"
                                                mdbWavesEffect>READ MORE</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 px-0">
                    <div class="view overlay zoom">
                        <img src="../../assets/images/work6.jpg" class="img-fluid w-100 h-100" alt="smaple image">
                        <div class="mask text-white">
                            <div class="w-100 overlaymaskContent">
                                <div class="row">
                                    <div class="col-md-12 px-5">
                                        <h2 class="sideheaddingsBorder">SERVICES</h2>
                                        <p class="h3 font-weight-bold">AR in Various industries to make User Experience
                                            Better.
                                        </p>

                                        <!-- <div>
                                            <button mdbBtn type="button" class="px-5 " size="sm" color="danger"
                                                mdbWavesEffect>READ MORE</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </section>


    <div class="container">
        <div class="row py-5">
            <div class="col-md-12">
                <p class="h1">
                    <span class="sideheadding sideheaddingScroll3 smFontheadder">SOME OF OUR CLIENTS</span> </p>
            </div>
            <div class="col-md-12 py-5">
                <marquee direction="left" behavior="scroll" scrollamount="12" class="someofourclients"
                    onmouseover='this.stop()' onmouseout='this.start()'>
                    <img src="/assets/Icons/1.png" alt="c1" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/2.png" alt="c2" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/3.png" alt="c3" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/4.png" alt="c4" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/5.png" alt="c5" class="img-fluid" style="width: 100px">
                    <img src="/assets/Icons/6.png" alt="c6" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/7.png" alt="c7" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/8.png" alt="c8" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/9.png" alt="c9" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/10.png" alt="c10" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/11.png" alt="c11" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/12.png" alt="c12" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/13.png" alt="c13" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/14.png" alt="c14" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/15.png" alt="c15" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/16.png" alt="c16" class="img-fluid" style="width: 100px">
                    <img src="assets/Icons/17.png" alt="c17" class="img-fluid" style="width: 100px">
                  

                </marquee>
                <!-- <div class="row my-5 justify-content-between">
                        <div class="col-md-2 py-4 text-center">
                            <img src="/assets/Icons/1.png" alt="c1" class="img-fluid">
                        </div>
                        <div class="col-md-2 py-4 text-center">
                            <img src="assets/Icons/2.png" alt="c2" class="img-fluid">
                        </div>
                        <div class="col-md-2 py-4 text-center">
                            <img src="assets/Icons/3.png" alt="c3" class="img-fluid">
                        </div>
                        <div class="col-md-2 py-4 text-center">
                            <img src="assets/Icons/4.png" alt="c4" class="img-fluid">
                        </div>
                        <div class="col-md-2 py-4 text-center">
                            <img src="assets/Icons/5.png" alt="c5" class="img-fluid">
                        </div>

                        <div class="col-md-2 py-4 text-center">
                            <img src="/assets/Icons/1.png" alt="c1" class="img-fluid">
                        </div>
                        <div class="col-md-2 py-4 text-center">
                            <img src="assets/Icons/2.png" alt="c2" class="img-fluid">
                        </div>
                        <div class="col-md-2 py-4 text-center">
                            <img src="assets/Icons/3.png" alt="c3" class="img-fluid">
                        </div>
                        <div class="col-md-2 py-4 text-center">
                            <img src="assets/Icons/4.png" alt="c4" class="img-fluid">
                        </div>
                        <div class="col-md-2 py-4 text-center">
                            <img src="assets/Icons/5.png" alt="c5" class="img-fluid">
                        </div>
                    </div> -->
            </div>

        </div>
    </div>





    <app-footer></app-footer>


</section>