<section>

    <div class="view" style="height: 70vh;">
        <img src="assets/images/services.jpg" class="img-fluid" alt="smaple image">
        <div class="mask flex-center rgba-black-strong">
            <div class="container  text-white">
                <p class="h1 header mb-4 pt-4">Executive profiles </p>
                <p class="headderContent h4 mb-4 w-75">
                    With our track record of performance, our exceptional team, diverse customer base and strong values
                    to guide us, we’re well positioned in this new decade and beyond.
                </p>
            </div>
        </div>
    </div>

    <div class="container py-4">
        <div class="row py-5">
            <div class="col-md-12">
                <p class="h2 text-center">
                    Meet the leaders who are driving our ongoing transformation.
                </p>
            </div>
            <div class="col-md-12">
                <div class="row pt-3">
                    <div class="col-md-4">
                        <div class="flex-center px-5">
                            <div class="text-center">
                                <div class="profileImgBox">
                                    <img src="assets/images/about/user.png" class="img-fluid" alt="smaple image">
                                </div>
                                <h2 class="mb-2">Surya Vamsi</h2>
                                <p> Chairman, President,
                                    and Chief Executive Officer</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex-center px-5">
                            <div class="text-center">
                                <div class="profileImgBox">
                                    <img src="assets/images/about/user.png" class="img-fluid" alt="smaple image">
                                </div>
                                <h2 class="mb-2">Surya Vamsi</h2>
                                <p> Chairman, President,
                                    and Chief Executive Officer</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex-center px-5">
                            <div class="text-center">
                                <div class="profileImgBox">
                                    <img src="assets/images/about/siva.png" class="img-fluid" alt="smaple image">
                                </div>
                                <h2 class="mb-2">Siva Krishna</h2>
                                <p> UI </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-md-4">
                        <div class="flex-center px-5">
                            <div class="text-center">
                                <div class="profileImgBox">
                                    <img src="assets/images/about/user.png" class="img-fluid" alt="smaple image">
                                </div>
                                <h2 class="mb-2">Surya Vamsi</h2>
                                <p> Chairman, President,
                                    and Chief Executive Officer</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex-center px-5">
                            <div class="text-center">
                                <div class="profileImgBox">
                                    <img src="assets/images/about/user.png" class="img-fluid" alt="smaple image">
                                </div>
                                <h2 class="mb-2">Surya Vamsi</h2>
                                <p> Chairman, President,
                                    and Chief Executive Officer</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex-center px-5">
                            <div class="text-center">
                                <div class="profileImgBox">
                                    <img src="assets/images/about/user.png" class="img-fluid" alt="smaple image">
                                </div>
                                <h2 class="mb-2">Surya Vamsi</h2>
                                <p> Chairman, President,
                                    and Chief Executive Officer</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-md-4">
                        <div class="flex-center px-5">
                            <div class="text-center">
                                <div class="profileImgBox">
                                    <img src="assets/images/about/user.png" class="img-fluid" alt="smaple image">
                                </div>
                                <h2 class="mb-2">Surya Vamsi</h2>
                                <p> Chairman, President,
                                    and Chief Executive Officer</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex-center px-5">
                            <div class="text-center">
                                <div class="profileImgBox">
                                    <img src="assets/images/about/user.png" class="img-fluid" alt="smaple image">
                                </div>
                                <h2 class="mb-2">Surya Vamsi</h2>
                                <p> Chairman, President,
                                    and Chief Executive Officer</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex-center px-5">
                            <div class="text-center">
                                <div class="profileImgBox">
                                    <img src="assets/images/about/user.png" class="img-fluid" alt="smaple image">
                                </div>
                                <h2 class="mb-2">Surya Vamsi</h2>
                                <p> Chairman, President,
                                    and Chief Executive Officer</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-5 border-top">
        <div class="row my-5 py-5">
            <div class="col-md-6">
                <div class="view">
                    <img src="assets/images/services.jpg" class="img-fluid" alt="smaple image">
                </div>
            </div>
            <div class="col-md-6">
                <div class="flex-center p-5">
                    <div>
                        <p class="h3">Empowering every voice. </p>
                        <br>
                        <p>
                            We provide people of all ages and backgrounds with grants, product donations and
                            educational opportunities so they can tell their stories, express themselves and build
                            great careers.
                        </p>
                        <div class="pt-3">
                            <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                                mdbWavesEffect>Learn
                                more</button>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
 

        <div class="container my-5 border-top ">
           
            <div class="row justify-content-center my-5 py-5">
             
                <div class="col-md-6 my-3">
                    <mdb-card class="servicesCards">
                        <mdb-card-img src="assets/images/about/c3.jpg" alt="Card image cap" class="border-top-radius">
                        </mdb-card-img>
                        <mdb-card-body class="black text-white border-bottom-radius ">
                            <h2 class="servicesCardsTitle">
                                <span> AI as a Service </span>
                            </h2>
                            <div class="pt-3">
                                <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                                    mdbWavesEffect>Learn
                                    more</button>
                            </div>
                        </mdb-card-body>
                    </mdb-card>
                </div>
                <div class="col-md-6 my-3">
                    <mdb-card class="servicesCards">
                        <mdb-card-img src="assets/images/about/c3.jpg" alt="Card image cap" class="border-top-radius">
                        </mdb-card-img>
                        <mdb-card-body class="black text-white border-bottom-radius ">
                            <h2 class="servicesCardsTitle">
                                <span> AI as a Service </span>
                            </h2>
                            <div class="pt-3">
                                <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                                    mdbWavesEffect>Learn
                                    more</button>
                            </div>
                        </mdb-card-body>
                    </mdb-card>
                </div>
                <div class="col-md-6 my-3">
                    <mdb-card class="servicesCards">
                        <mdb-card-img src="assets/images/about/c3.jpg" alt="Card image cap" class="border-top-radius">
                        </mdb-card-img>
                        <mdb-card-body class="black text-white border-bottom-radius ">
                            <h2 class="servicesCardsTitle">
                                <span> AI as a Service </span>
                            </h2>
                            <div class="pt-3">
                                <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                                    mdbWavesEffect>Learn
                                    more</button>
                            </div>
                        </mdb-card-body>
                    </mdb-card>
                </div>
                <div class="col-md-6 my-3">
                    <mdb-card class="servicesCards">
                        <mdb-card-img src="assets/images/about/c3.jpg" alt="Card image cap" class="border-top-radius">
                        </mdb-card-img>
                        <mdb-card-body class="black text-white border-bottom-radius ">
                            <h2 class="servicesCardsTitle">
                                <span> AI as a Service </span>
                            </h2>
                            <div class="pt-3">
                                <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                                    mdbWavesEffect>Learn
                                    more</button>
                            </div>
                        </mdb-card-body>
                    </mdb-card>
                </div>


            </div>
        </div>



</section>