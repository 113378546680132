import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-about-us-all',
  templateUrl: './about-us-all.component.html',
  styleUrls: ['./about-us-all.component.scss']
})
export class AboutUsAllComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    // $(window).scroll(() => {
    //   $('.mainNavbar').toggleClass('scrolled', $(this).scrollTop() > 50);
    //   $('.mainNavbar').toggleClass('d-block', $(this).scrollTop() < 50);
    //   $('.mainNavbar').toggleClass('d-none', $(this).scrollTop() > 50);
    // });

    $(window).scroll(function () {
      $('.mainNavbar').toggleClass('scrolled', $(this).scrollTop() > 50);
      $('.mainNavbar').toggleClass('d-block', $(this).scrollTop() < 50);
      $('.mainNavbar').toggleClass('d-none', $(this).scrollTop() > 70);

      $('.aboutsubrains').toggleClass('aboutsubrainsblack', $(this).scrollTop() > 50);
      $('.subnavbar nav').toggleClass('navbar-light white', $(this).scrollTop() > 50);
      $('.subnavbar nav').toggleClass('navbar-dark stylish-color', $(this).scrollTop() < 50);


    });


  }

}
