import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-social-responcibility',
  templateUrl: './corporate-social-responcibility.component.html',
  styleUrls: ['./corporate-social-responcibility.component.scss']
})
export class CorporateSocialResponcibilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
