import { Component, OnInit, ElementRef } from '@angular/core';
declare var $: any;
import {
  DomSanitizer,
  SafeHtml,
  SafeUrl,
  SafeStyle
} from '@angular/platform-browser';

@Component({
  selector: 'app-products-solutions',
  templateUrl: './products-solutions.component.html',
  styleUrls: ['./products-solutions.component.scss']
})
export class ProductsSolutionsComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer) { }

  // slides: any = [[
  //   {
  //     title: 'Conversation SUNNY',
  //     description: 'Sunny is most intelligent conversational AI Engine, available with Different domain Knowledge base compatible with any platform of integration',
  //     buttonText: 'Button',
  //     img:this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
  //     img1: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
  //     img2: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596986045/servicecards1_jtg4gq.gif`),
  //   },
  //   {
  //     title: 'Computer vision',
  //     description: 'Our Models of Computer vision Models are pretrainined with multiple Image dataset flexible to be adopted in multiple data implementations.',
  //     buttonText: 'Button',
  //     img:this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
  //     img1: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
  //     img2: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596987806/facerecog_faadlr.gif')`),
  //   },
  //   {
  //     title: 'Codewind ',
  //     description: 'Next-Gen Cloud IDE, which helps developers and organisations connect with world wide and complete projects with effective collaboration and concurrency',
  //     buttonText: 'Button',
  //     img:this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
  //     img1: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
  //     img2: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596989269/service3_vfbutn.gif`),
  //   },
  // ]
  
  // ];
  slides: any = [
    {
      title: 'Conversation SUNNY',
      description: 'Sunny is most intelligent conversational AI Engine, available with Different domain Knowledge base compatible with any platform of integration',
      buttonText: 'Button',
      img:this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
      img1: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
      img2: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596986045/servicecards1_jtg4gq.gif`),
    },
    {
      title: 'Computer vision',
      description: 'Our Models of Computer vision Models are pretrainined with multiple Image dataset flexible to be adopted in multiple data implementations.',
      buttonText: 'Button',
      img:this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
      img1: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
      img2: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596987806/facerecog_faadlr.gif')`),
    },
    {
      title: 'Codewind ',
      description: 'Next-Gen Cloud IDE, which helps developers and organisations connect with world wide and complete projects with effective collaboration and concurrency',
      buttonText: 'Button',
      img:this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
      img1: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596994231/black_p8cdam.jpg')`),
      img2: this.sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/donehsyjz/image/upload/v1596989269/service3_vfbutn.gif`),
    },
  ];
  
  ngOnInit(): void {

    $(window).scroll(function () {
      // $('.mainNavbar').toggleClass('scrolled', $(this).scrollTop() > 50);
      // $('.mainNavbar').toggleClass('d-block', $(this).scrollTop() < 50);
      // $('.mainNavbar').toggleClass('d-none', $(this).scrollTop() > 70);

      $('.aboutsubrains').toggleClass('aboutsubrainsblack', $(this).scrollTop() > 50);
      $('.subnavbar nav').toggleClass('navbar-light white', $(this).scrollTop() > 50);
      $('.subnavbar nav').toggleClass('navbar-dark stylish-color', $(this).scrollTop() < 50);



      // $('.aboutsubrains').toggleClass('aboutsubrainsblack', $(this).scrollTop() > 50);
    });

  }

  changeBackground(card) {
    card.img = card.img2
  }
  LeaveBackground(card) {
    card.img = card.img1
  }

}
