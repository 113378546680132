import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contactUs = {
    Comments: "",
    Email: "",
    MobileNumber: null,
    Name: "",
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    // private CommonService: CommonService,
    // private toastr: ToastrService

  ) { }

  ngOnInit(): void {
  }


  async send() {
    // var url = APIURL.ENQUIRY_INSERTION;
    // return this.CommonService.postMethod(url, this.contactUs)
    //   .then(async (data: Data) => {
    //     if (data.Status == 200) {
    //       this.toastr.success( data.Message , "Success !");
    //       this.contactUs = {
    //         Name: "",
    //         Email: "",
    //         MobileNumber: null,
    //         Comments: ""
    //       }
    //     }else{
    //       this.toastr.warning( data.Message , "Error !");
    //     }
    //   })
  }

  async openService(num) {
    this.router.navigate(['/services/' + num]);
  }
  
}