<section>

    <div class="view" style="height: 70vh;">
        <img src="assets/images/services.jpg" class="img-fluid" alt="smaple image">
        <div class="mask flex-center rgba-black-strong">
            <div class="container  text-white">
                <p class="h1 header mb-4 pt-4">Together, we create change. </p>
                <p class="headderContent h4 mb-4 w-75">
                    Great experiences have the power to inspire, transform, and move the world forward. And every great
                    experience starts with creativity.
                </p>
                <div>
                    <button mdbBtn type="button" color="danger" class="btnWithHoverOnluborder m-0"
                        mdbWavesEffect> Watch Video </button>
                </div>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="row py-5">
            <div class="col-md-12">
                <div class="row py-5">
                    <div class="col-md-5">
                        <div class="flex-center p-5">
                            <div>
                                <p class="h3">Empowering every voice. </p>
                                <br>
                                <p>
                                    We provide people of all ages and backgrounds with grants, product donations and
                                    educational opportunities so they can tell their stories, express themselves and build
                                    great careers.
                                </p>
                                <div class="pt-3">
                                    <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                                        mdbWavesEffect>Learn
                                        more</button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="view">
                            <img src="assets/images/services.jpg" class="img-fluid" alt="smaple image">
                        </div>
                    </div>
                </div>
                <div class="row py-5">
                    <div class="col-md-7">
                        <div class="view">
                            <img src="assets/images/services.jpg" class="img-fluid" alt="smaple image">
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="flex-center p-5">
                            <div>
                                <p class="h3">Empowering every voice. </p>
                                <br>
                                <p>
                                    We provide people of all ages and backgrounds with grants, product donations and
                                    educational opportunities so they can tell their stories, express themselves and build
                                    great careers.
                                </p>
                                <div class="pt-3">
                                    <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                                        mdbWavesEffect>Learn
                                        more</button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div class="row py-5">
                    <div class="col-md-5">
                        <div class="flex-center p-5">
                            <div>
                                <p class="h3">Empowering every voice. </p>
                                <br>
                                <p>
                                    We provide people of all ages and backgrounds with grants, product donations and
                                    educational opportunities so they can tell their stories, express themselves and build
                                    great careers.
                                </p>
                                <div class="pt-3">
                                    <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                                        mdbWavesEffect>Learn
                                        more</button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="view">
                            <img src="assets/images/services.jpg" class="img-fluid" alt="smaple image">
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>


    <div class="row ">
        <div class="col-md-12">
            <div class="view white-text">
                <img src="assets//images/about/c5.jpg" alt="c1" class="w-100" height="250px">
                <div class="mask flex-center rgba-gray-strong container">
                    <div class="mr-auto">
                        <p class="h2">Are you a nonprofit looking to collaborate with subrains?  </p>
                        <div class="pt-3">
                            <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0 btn-rounded"
                                mdbWavesEffect>download our resource guide</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


</section>