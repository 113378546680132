<section>

    <div class="view" style="height: 70vh;">
        <img src="assets/images/services.jpg" class="img-fluid" alt="smaple image">
        <div class="mask flex-center rgba-black-strong">
            <div class="container  text-white">
                <p class="h1 header mb-4 pt-4">Executive profiles </p>
                <p class="headderContent h4 mb-4 w-75">
                    With our track record of performance, our exceptional team, diverse customer base and strong values
                    to guide us, we’re well positioned in this new decade and beyond.
                </p>
            </div>
        </div>
    </div>

    <div class="container py-4">
        <div class="row py-5">
            <div class="col-md-12">
                <p class="h2 text-center">
                    What’s your passion?
                </p>
                <p class="text-center">Explore amazing careers at Subrains.</p>
            </div>
            <div class="col-md-12 pt-5">
                <div class="row">
                    <div class="col-md-4 p-1">
                        <div class="view overlay zoom photos">
                            <img src="../../assets/images/1.jpg" class="img-fluid w-100" alt="smaple image">
                            <div class="mask text-white">
                                <div class="w-100 overlaymaskContent">
                                    <div class="row">
                                        <div class="col-md-12 px-5">
                                            <h2 class="sideheaddingsBorder">Services</h2>
                                            <p class="h6 font-weight-bold">Face Recognisation and Emotions on COVID-19
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 p-1">
                        <div class="view overlay zoom photos">
                            <img src="../../assets/images/2.jpg" class="img-fluid w-100" alt="smaple image">
                            <div class="mask text-white">
                                <div class="w-100 overlaymaskContent">
                                    <div class="row">
                                        <div class="col-md-12 px-5">
                                            <h2 class="sideheaddingsBorder">Products</h2>
                                            <p class="h6 font-weight-bold">LMS model For students & teachers Of schools
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 p-1">
                        <div class="view overlay zoom photos">
                            <img src="../../assets/images/1.jpg" class="img-fluid w-100" alt="smaple image">
                            <div class="mask text-white">
                                <div class="w-100 overlaymaskContent">
                                    <div class="row">
                                        <div class="col-md-12 px-5">
                                            <h2 class="sideheaddingsBorder">Products</h2>
                                            <p class="h6  font-weight-bold"> Rethinking mobility teachers Of schools</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 p-1">
                        <div class="view overlay zoom photos">
                            <img src="../../assets/images/1.jpg" class="img-fluid w-100" alt="smaple image">
                            <div class="mask text-white">
                                <div class="w-100 overlaymaskContent">
                                    <div class="row">
                                        <div class="col-md-12 px-5">
                                            <h2 class="sideheaddingsBorder">Services</h2>
                                            <p class="h6 font-weight-bold">Face Recognisation and Emotions on COVID-19
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-1">
                        <div class="view overlay zoom photos">
                            <img src="../../assets/images/2.jpg" class="img-fluid w-100" alt="smaple image">
                            <div class="mask text-white">
                                <div class="w-100 overlaymaskContent">
                                    <div class="row">
                                        <div class="col-md-12 px-5">
                                            <h2 class="sideheaddingsBorder">Products</h2>
                                            <p class="h6 font-weight-bold">LMS model For students & teachers Of schools
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 p-1">
                        <div class="view overlay zoom photos">
                            <img src="../../assets/images/1.jpg" class="img-fluid w-100" alt="smaple image">
                            <div class="mask text-white">
                                <div class="w-100 overlaymaskContent">
                                    <div class="row">
                                        <div class="col-md-12 px-5">
                                            <h2 class="sideheaddingsBorder">Services</h2>
                                            <p class="h6 font-weight-bold">Face Recognisation and Emotions on COVID-19
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 p-1">
                        <div class="view overlay zoom photos">
                            <img src="../../assets/images/2.jpg" class="img-fluid w-100" alt="smaple image">
                            <div class="mask text-white">
                                <div class="w-100 overlaymaskContent">
                                    <div class="row">
                                        <div class="col-md-12 px-5">
                                            <h2 class="sideheaddingsBorder">Products</h2>
                                            <p class="h6 font-weight-bold">LMS model For students & teachers Of schools
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 p-1">
                        <div class="view overlay zoom photos">
                            <img src="../../assets/images/1.jpg" class="img-fluid w-100" alt="smaple image">
                            <div class="mask text-white">
                                <div class="w-100 overlaymaskContent">
                                    <div class="row">
                                        <div class="col-md-12 px-5">
                                            <h2 class="sideheaddingsBorder">Products</h2>
                                            <p class="h6  font-weight-bold"> Rethinking mobility teachers Of schools</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>

    <div class="container mt-5 border-top">
        <div class="row my-5 py-5">
            <div class="col-md-6">
                <div class="view">
                    <img src="assets/images/services.jpg" class="img-fluid" alt="smaple image">
                </div>
            </div>
            <div class="col-md-6">
                <div class="flex-center p-5">
                    <div>
                        <p class="h3">Empowering every voice. </p>
                        <br>
                        <p>
                            We provide people of all ages and backgrounds with grants, product donations and
                            educational opportunities so they can tell their stories, express themselves and build
                            great careers.
                        </p>
                        <div class="pt-3">
                            <button mdbBtn type="button" size="sm" color="danger" class="btnWithHoverOnluborder m-0"
                                mdbWavesEffect>Learn
                                more</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>



    <!-- <div class="row ">
        <div class="col-md-12">
            <div class="view white-text">
                <img src="assets//images/about/c5.jpg" alt="c1" class="w-100" height="250px">
                <div class="mask flex-center rgba-gray-strong container">
                    <div class="mr-auto">
                        <p class="h2">Are you a nonprofit looking to collaborate with subrains? </p>
                        <div class="pt-3">
                            <button mdbBtn type="button" size="sm" color="danger"
                                class="btnWithHoverOnluborder m-0 btn-rounded" mdbWavesEffect>download our resource
                                guide</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div> -->


    <div class="row">
        <div class="col-md-12">
            <div class="view white-text">
                <img src="assets//images/about/c5.jpg" alt="c1" class="w-100" height="400px">
                <div class="mask flex-center rgba-gray-strong">
                    <div class="text-center">
                        <div class="row justify-content-center">
                            <div class="col-md-7">
                                <p class="h2"> Social Media </p>
                                <br>
                                <p>
                                    Internships. Spend a few months in San Jose, San Francisco, New York, or a key site
                                    in Asia or Europe getting real experience on real business projects. Mentorship,
                                    great pay, purpose, and fun — it’s all here, along with the possibility of a
                                    full-time position once you graduate.
                                </p>
                                <br>
                                <p>
                                    Full-time positions. Launch your career and make your mark with an industry leader.
                                    Use your expertise and ideas to help us define how companies do business, and join a
                                    team where reinvention and experimentation are part of the creative culture.
                                </p>
                                <div class="pt-4">
                                    <button mdbBtn type="button" size="sm" color="danger"
                                        class="btnWithHoverOnluborder m-0" mdbWavesEffect>Learn
                                        more</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="row primary-color-dark">
        <div class="col-md-12 py-5 my-5 ">
            <div class="text-center text-white">
                <div class="row justify-content-center">
                    <div class="col-md-7">
                        <p class="h2"> Keep up with #AdobeLife. </p>
                        <p>Our latest updates.</p>
                        <br>
                      <div class="row">
                          <div class="col-md-4">
                              <p class="h5">
                                Indeed announces  as the #1 Top-Rated 
                              </p>
                              <div class="pt-3">
                                <button mdbBtn type="button" size="sm" color="danger"
                                    class="btnWithHoverOnluborder m-0" mdbWavesEffect>Learn
                                    more</button>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <p class="h5">
                              Indeed announces  as the #1 Top-Rated Workplace
                            </p>
                            <div class="pt-3">
                              <button mdbBtn type="button" size="sm" color="danger"
                                  class="btnWithHoverOnluborder m-0" mdbWavesEffect>Learn
                                  more</button>
                          </div>
                        </div>
                        <div class="col-md-4">
                            <p class="h5">
                              Indeed as the #1 Top-Rated Workplace
                            </p>
                            <div class="pt-3">
                              <button mdbBtn type="button" size="sm" color="danger"
                                  class="btnWithHoverOnluborder m-0" mdbWavesEffect>Learn
                                  more</button>
                          </div>
                        </div>
                      </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


</section>