import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {CommonExportModule} from './customExports/commonModules.module'


import { CommonService } from './Providers/common.service';

// import { RellaxDirective } from 'ng-rellax'
import { RellaxDirective } from './directives/rellax.directive';

import { HomeComponent } from './home/home.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ServicesComponent } from './services/services.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

// import { ServicespageComponent } from './AllServices/services/services.component';
import { AboutUsAllComponent } from './About/about-us-all/about-us-all.component';
import { NewsroomComponent } from './About/newsroom/newsroom.component';
import { CorporateSocialResponcibilityComponent } from './About/corporate-social-responcibility/corporate-social-responcibility.component';
import { LeadersComponent } from './About/leaders/leaders.component';
import { AboutSubrainsComponent } from './About/about-subrains/about-subrains.component';
import { GallaeryComponent } from './About/gallaery/gallaery.component';
import { ProductsComponent } from './products/products.component';
import { ProductsSolutionsComponent } from './products-solutions/products-solutions.component';
import { ProductsSolutionsHeaderComponent } from './products-solutions-header/products-solutions-header.component'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LandingPageComponent,
    FooterComponent,
    NavbarComponent,
    ServicesComponent,
    ContactUsComponent,
    // ServicespageComponent,
    AboutUsAllComponent,
    NewsroomComponent,
    CorporateSocialResponcibilityComponent,
    LeadersComponent,
    AboutSubrainsComponent,
    GallaeryComponent,
    ProductsComponent,
    RellaxDirective,
    ProductsSolutionsComponent,
    ProductsSolutionsHeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CountUpModule,
    HttpModule,
    HttpClientModule,
    CommonExportModule
  ],
  providers: [CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
