import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  authToken = "";
  
  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  async getHeaders() {
    const options = { headers: {} };
    options.headers['Content-Type'] = 'application/json';
    options.headers['Access-Control-Allow-Origin'] = '*';
    return options;

  }

  async postMethod(url, data): Promise<any> {
    let headers = await this.getHeaders();
    return this.http.post(url, data, headers).toPromise().then(
      (response: Data) => {
        return response
      })
  }

  async getMethod(url): Promise<any> {
    let headers = await this.getHeaders();
    return this.http.get(url, headers).toPromise().then(response => {
      return response
    })
  }


  // isLoggedIn() {
  //   // this.loadToken()
  //   return !!localStorage.getItem('userInfo');
  // }
  // loadToken() {
  //   const token = localStorage.getItem('jwtToken');
  //   this.authToken = token;
  // }

  // getuserInfo() {
  //   if (this.isLoggedIn() && !isNullOrUndefined(localStorage.getItem('userInfo')) && localStorage.getItem('userInfo') != 'undefined') {
  //     const userDetails = JSON.parse(this.EncrDecrService.get(localStorage.getItem('userInfo')));
  //     return userDetails;
  //   } else {
  //     return null;
  //   }
  // }




}
