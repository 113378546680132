<section>
  <div class="mainNavbar">
    <app-navbar></app-navbar>
  </div>

  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark stylish-color sticky-top" class="subnavbar">
    <links class="mx-auto w-100">
      <a class="navbar-brand aboutsubrains" href="javascript:void(0)" routerLink="/about">SyberzenLabs</a>
      <ul class="navbar-nav mr-auto mt-lg-0">
        <li class="nav-item hoverUnderLine" routerLinkActive="nav-active">
          <a class="nav-link" href="javascript:void(0)"> Our Products </a>
        </li>
        <!-- <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" routerLink="/about/gallaery" routerLinkActive="active">about subrains</a>
                </li> -->
      </ul>
    </links>
  </mdb-navbar>




  <div class="parallax">

    <section class="module parallax parallax-1 " data-type="background" data-speed="10">
      <div class="parallax-container ">
        <h1 class="parallaxHeadding">unleash innovation </h1>
        <p class="h2 text-white text-center mt-5">Through digital product design</p>

        <div class="text-center circle-line-Img">
          <img src="assets/images/circle-line.png" alt="no line" class="img-fluid">
        </div>
      </div>
      <div class="parallax-container">

        <p class="h2 text-white text-center mt-5">UNLOCK ITS POWER</p>
        <h1 class="parallaxHeadding">Release data’s value with AI</h1>
        <p class="h2 text-white text-center mt-5">AGGREGATE | ANALYZE | ACCELERATE</p>
        <p class="h2 text-white text-center mt-5 indigo-text">LET'S GO</p>
        <div class="text-center">
          <img src="assets/images/scrollimg.gif" alt="no line" class="img-fluid" width="50px">
        </div>
        <div class="text-center circle-line-Imgs">
          <img src="assets/images/circle-line.png" alt="no line" class="img-fluid">
        </div>
      </div>
    </section>

    <section class="module content elegant-color-dark">
      <div class="container-fluid px-5">
        <div class="row">
          <div class="col-md-3 text-white">
            <p class="fontsize70 h1 ">Our <br> Services</p>
            <p class="h3">Next we'll set the height of each of our steps.</p>
          </div>
          <div class="col-md-9">
            <mdb-carousel [isControls]="true" class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'"
              [animation]="'slide'">
              <mdb-carousel-item *ngFor="let item of slides; let i = index">
                <div class="row">
                  <div class="col-md-4" [ngClass]="{'d-none d-md-block': cardIndex !== 0}"
                    *ngFor="let card of item; let cardIndex = index">
                    <mdb-card class="my-1">
                      <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                      <mdb-card-body>
                        <mdb-card-title>
                          <h4>{{card.title}}</h4>
                        </mdb-card-title>
                        <p>{{card.description}}</p>
                        <a href="#" mdbBtn color="primary" class="waves-light" mdbWavesEffect>{{card.buttonText}}</a>
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                </div>
              </mdb-carousel-item>
            </mdb-carousel>
          </div>
        </div>


      </div>
    </section>

    <section class="module pt-5 parallax-2 white" data-type="background" data-speed="10">
      <div class="row">
        <div class="col-md-6 pr-0">
          <p class="h1 text-center w-75 float-right">You need to set isControls </p>
          <div class="px-0 pt-5 mt-5 text-right">
            <img src="/assets/images/service1.jpg" alt="no img" class="img-fluid mt-5 pt-5" width="500px">
          </div>
        </div>
        <div class="col-md-6 pl-0">
          <p class="h5">You need to set isControls input to false and add your own buttons with previousSlide and
            nextSlide
            methods. Here is an example:
            You need to set isControls input to false and add your own buttons with previousSlide and nextSlide
            methods. Here is an example:</p>
          <img src="/assets/images/work5.jpg" alt="no img" class="img-fluid" width="100%">

        </div>
      </div>
    </section>


    <div>
      <div class="view" style="height: 600px;">
        <img src=" assets/images/contact.jpg" class="img-fluid d-block w-100" alt="smaple image">
        <div class="mask flex-center container">
          <div class="text-center w-75">
            <p class="h1 fontsize70 text-white">Let's co-create the future</p>
            <p class="h5 mt-4 text-white">Join a long list of satisfied clients, partners, and successful businesses
              that we have had the pleasure of working with</p>
            <div class="mt-4">
              <button mdbBtn type="button" color="danger" class="btnWithHoverOnluborder" mdbWavesEffect
                routerLink="/contactus">Get in Touch</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <app-footer></app-footer>
  </div>
</section>