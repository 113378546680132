<div class="black">

    <div class="wrap">
        <!-- <div class="back">
        <div class="back__slide card_active">
            <div class="progress"></div>
        </div>
        <div class="back__slide">
            <div class="progress"></div>
            <div class="back-front-image"></div>
        </div>
    </div> -->
        <div class="card blockcard">
            <div class="card__slide card_active">
                <div class="image"></div><span class="number">01</span>
            </div>
            <div class="card__slide">
                <div class="back-image"></div>
                <div class="image"></div><span class="number">02</span>
            </div>
            <div class="content">
                <div class="content__slide card_active">
                    <h4 class="title"> <span class="title__line"> <span class="title__inner  font-sm-h"> BUILT OUT OF
                                PASSION</span>
                            </span>
                            <!-- <span class="title__line"> <span
                                class="title__inner"></span>
                            </span> -->
                        </h4>
                    <p class="desc  font-sm-p">We’re Syberzenlabs. We bring together strategy, design, engineering and data to help
                        organisations become resilient, so they can take control of their futures.</p>
  
                    <div class="button-wrap"><a class="button">
                            <mdb-icon fas icon="angle-double-right" size="lg"></mdb-icon><span class="button__hover"></span>
                        </a>
                    </div>
                </div>
                <div class="content__slide">
                    <h2 class="title"> <span class="title__line"> <span class="title__inner font-sm-h">THINGS WE DO
                            </span></span>
                            <!-- <span class="title__line"> <span class="title__inner"></span></span> -->
                    </h2>
                    <p class="desc  font-sm-p">We help our clients unleash innovation through digital product design and build,
                        emerging technology, agile software development and lean organisational change</p>
                    <div class="button-wrap"><a class="button">
                            <mdb-icon fas icon="angle-double-right" size="lg"></mdb-icon>
                            <span class="button__hover"></span>
                        </a>
                    </div>
                </div>
                <div class="content__ping content__ping--noanimation"></div>
            </div>
        </div>
    </div>
</div>