<section>

    <div>
        <app-navbar></app-navbar>
    </div>

    <div class="view Servicebanner">
        <img [src]="SelectedService?.BgImage" class="img-fluid w-100 h-100" alt="smaple image" width="100%">
        <div class="mask flex-center rgba-black-strong">
            <div class="container text-white">
                <div class="row">
                    <div class="col-md-6">
                        <p class="h1 header mt-5 mb-4 pt-4 my-sm-0 font-sm-h" style="font-size:80px">
                            {{SelectedService?.ServiceName}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container py-5">
        <div class="row">

            <div class="col-md-4 contentCard">
                <mdb-card> 
                    <mdb-card-body>
                        <h3>
                            {{SelectedService?.Description1}}
                        </h3>
                    </mdb-card-body>
                </mdb-card>
            </div>
            <div class="col-md-8">
                <h3><strong>
                        {{SelectedService?.Description}}
                    </strong>
                </h3>
            </div>
        </div>
    </div>


    <!-- <p class="headderContent h5 mb-5 wow fadeInUp infinite">
        {{SelectedService?.Description}}
        </p> -->

    <div>
        <!-- <div class="container-fluid">
            <div class="row  p-5">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 p-5">
                            <img [src]="SelectedService?.Set1.Image" alt="no img" class="img-fluid ml-5" width="100%">
                        </div>
                        <div class="col-md-6 p-5 ">
                            <div class="ml-5">

                                <p class="h1 mb-4">{{SelectedService?.Set1.Title}}</p>

                                <div>
                                    <p class="h6 lineheight-p"> 
                                        {{SelectedService?.Set1.Description}}
                                    </p>
                                </div>
                                <div>
                                    <ul class="list-group list-group-flush customlist">
                                        <li class="list-group-item d-flex" *ngFor="let item of SelectedService?.Set1.List">
                                            <mdb-icon far icon="check-square" class="mr-2 red-text"></mdb-icon> {{item.Item}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="mt-4">
                                    <button mdbBtn type="button" color="danger" class="btnWithHoverOnluborder"
                                        mdbWavesEffect>Learn
                                        more</button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div> -->



        <div class="container-fluid grey lighten-5">
            <div class="row px-5 Capabilities">
                <div class="col-md-12">
                    <div class="row my-5 justify-content-center">
                        <div class="col-md-7 text-center">
                            <p class="h1 font-sm-h">Our Capabilities</p>
                            <br>
                            <p class="h5 lineheight-p">{{SelectedService?.ProvidingServices.Description}}</p>
                        </div>
                        <div class="col-md-12 m-5 mx-sm-0">
                            <div class="row">
                                <div class="col-md-4" *ngIf="SelectedService?.ProvidingServices.Service1">
                                    <div class="row flex-center">
                                        <div class="col-md-3 text-center my-5">
                                            <div class="service-icon-h animate__animated animate__fadeUp">
                                                <i class="flaticon-artificial-intelligence-1">
                                                    <img [src]="SelectedService?.ProvidingServices.Service1.image"
                                                        alt="" width="78px" class="img-fluid">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <p class="h3  mb-2">{{SelectedService?.ProvidingServices.Service1.Name}}
                                            </p>
                                            <p class="h6 lineheight-p mb-2">
                                                {{SelectedService?.ProvidingServices.Service1.Description}}</p>
                                            <div>
                                                <ul class="list-group list-group-flush customlist text-left">
                                                    <li class="list-group-item d-flex "
                                                        *ngFor="let item of SelectedService?.ProvidingServices.Service1.List">
                                                        <mdb-icon far icon="check-square" class="mr-2 red-text">
                                                        </mdb-icon>
                                                        {{item.Item}}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" *ngIf="SelectedService?.ProvidingServices.Service2">
                                    <div class="row flex-center">
                                        <div class="col-md-3 text-center  my-6">
                                            <div class="service-icon-v animate__animated animate__fadeUp">
                                                <i class="flaticon-artificial-intelligence-1">
                                                    <img [src]="SelectedService?.ProvidingServices.Service2.image"
                                                        alt="" width="78px" class="img-fluid">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <p class="h3  mb-2">{{SelectedService?.ProvidingServices.Service2.Name}}
                                            </p>
                                            <p class="h6 lineheight-p mb-2">
                                                {{SelectedService?.ProvidingServices.Service2.Description}}</p>
                                            <div>
                                                <ul class="list-group list-group-flush customlist text-left">
                                                    <li class="list-group-item d-flex"
                                                        *ngFor="let item of SelectedService?.ProvidingServices.Service2.List">
                                                        <mdb-icon far icon="check-square" class="mr-2 red-text">
                                                        </mdb-icon> {{item.Item}}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" *ngIf="SelectedService?.ProvidingServices.Service3">
                                    <div class="row flex-center">
                                        <div class="col-md-3 text-center  my-5">
                                            <div class="service-icon-h animate__animated animate__fadeUp">
                                                <i class="flaticon-artificial-intelligence-1">
                                                    <img [src]="SelectedService?.ProvidingServices.Service3.image"
                                                        alt="" width="78px" class="img-fluid">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <p class="h3  mb-2">{{SelectedService?.ProvidingServices.Service3.Name}}
                                            </p>
                                            <p class="h6 lineheight-p mb-2">
                                                {{SelectedService?.ProvidingServices.Service3.Description}}</p>
                                            <div>
                                                <ul class="list-group list-group-flush customlist text-left">
                                                    <li class="list-group-item d-flex"
                                                        *ngFor="let item of SelectedService?.ProvidingServices.Service3.List">
                                                        <mdb-icon far icon="check-square" class="mr-2 red-text">
                                                        </mdb-icon> {{item.Item}}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 m-5">
                            <div class="row">
                                <div class="col-md-4" *ngIf="SelectedService?.ProvidingServices.Service4">
                                    <div class="row flex-center">
                                        <div class="col-md-3 text-center my-6">
                                            <div class="service-icon-v animate__animated animate__fadeUp">
                                                <i class="flaticon-artificial-intelligence-1">
                                                    <img [src]="SelectedService?.ProvidingServices.Service4.image"
                                                        alt="" width="78px" class="img-fluid">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <p class="h3  mb-2">{{SelectedService?.ProvidingServices.Service4.Name}}
                                            </p>
                                            <p class="h6 lineheight-p mb-2">
                                                {{SelectedService?.ProvidingServices.Service4.Description}}</p>
                                            <div>
                                                <ul class="list-group list-group-flush customlist text-left">
                                                    <li class="list-group-item d-flex"
                                                        *ngFor="let item of SelectedService?.ProvidingServices.Service4.List">
                                                        <mdb-icon far icon="check-square" class="mr-2 red-text">
                                                        </mdb-icon> {{item.Item}}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" *ngIf="SelectedService?.ProvidingServices.Service5">
                                    <div class="row flex-center">
                                        <div class="col-md-3 text-center my-5">
                                            <div class="service-icon-h animate__animated animate__fadeUp">
                                                <i class="flaticon-artificial-intelligence-1">
                                                    <img [src]="SelectedService?.ProvidingServices.Service5.image"
                                                        alt="" width="78px" class="img-fluid">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <p class="h3  mb-2">{{SelectedService?.ProvidingServices.Service5.Name}}
                                            </p>
                                            <p class="h6 lineheight-p mb-2">
                                                {{SelectedService?.ProvidingServices.Service5.Description}}</p>
                                            <div>
                                                <ul class="list-group list-group-flush customlist text-left">
                                                    <li class="list-group-item d-flex"
                                                        *ngFor="let item of SelectedService?.ProvidingServices.Service5.List">
                                                        <mdb-icon far icon="check-square" class="mr-2 red-text">
                                                        </mdb-icon> {{item.Item}}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" *ngIf="SelectedService?.ProvidingServices.Service5">
                                    <div class="row flex-center">
                                        <div class="col-md-3 text-center my-6">
                                            <div class="service-icon-v animate__animated animate__fadeUp">
                                                <i class="flaticon-artificial-intelligence-1">
                                                    <img [src]="SelectedService?.ProvidingServices.Service6.image"
                                                        alt="" width="78px" class="img-fluid">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <p class="h3  mb-2">{{SelectedService?.ProvidingServices.Service6.Name}}
                                            </p>
                                            <p class="h6 lineheight-p mb-2">
                                                {{SelectedService?.ProvidingServices.Service6.Description}}</p>
                                            <div>
                                                <ul class="list-group list-group-flush customlist text-left">
                                                    <li class="list-group-item d-flex"
                                                        *ngFor="let item of SelectedService?.ProvidingServices.Service6.List">
                                                        <mdb-icon far icon="check-square" class="mr-2 red-text">
                                                        </mdb-icon> {{item.Item}}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="SelectedService?.Carousel">
            <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
                <mdb-carousel-item *ngFor="let item of SelectedService?.Carousel">
                    <div class="view w-100">
                        <img class="d-block w-100" [src]="item.bgImage" alt="First slide" height="550px">
                        <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
                    </div>
                    <div class="carousel-caption serviceCarousel-caption">
                        <div>
                            <p class="h1 fontsize70 w-75 font-sm-h">{{item.Title}}</p>
                            <p class="h5 w-75 font-sm-p">{{item.Content}}</p>
                            <div class="mt-4">
                                <button mdbBtn type="button" color="danger" class="btnWithHoverOnluborder"
                                    mdbWavesEffect>Learn
                                    more</button>
                            </div>
                        </div>
                    </div>
                </mdb-carousel-item>
                <!-- <mdb-carousel-item>
                    <div class="view w-100">
                        <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(6).jpg"
                            alt="Second slide" height="550px">
                        <div class="mask rgba-black-strong waves-light" mdbWavesEffect></div>
                    </div>
                    <div class="carousel-caption serviceCarousel-caption">
                        <div>
                            <p class="h1 fontsize70 w-75"> Mask slides easily with the any</p>
                            <p class="h5 w-75">{{item}}</p>
                            <div class="mt-4">
                                <button mdbBtn type="button" color="danger" class="btnWithHoverOnluborder"
                                    mdbWavesEffect>Learn
                                    more</button>
                            </div>
                        </div>
                    </div>
                </mdb-carousel-item>
                <mdb-carousel-item>
                    <div class="view w-100">
                        <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg"
                            alt="Third slide" height="550px">
                        <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
                    </div>
                    <div class="carousel-caption serviceCarousel-caption">
                        <div>
                            <p class="h1 fontsize70 w-75">Light slides easily with the any</p>
                            <p class="h5 w-75">Add captions to your slides easily with the .carousel-caption element
                                within
                                any
                                mdb-carousel-item. They can be easily hidden on smaller viewports, as shown below, with
                                optional
                                display utilities. We hide them initially with .d-none and bring them back on
                                medium-sized
                                devices with .d-md-block.</p>
                            <div class="mt-4">
                                <button mdbBtn type="button" color="danger" class="btnWithHoverOnluborder"
                                    mdbWavesEffect>Learn
                                    more</button>
                            </div>
                        </div>
                    </div>
                </mdb-carousel-item> -->
            </mdb-carousel>
        </div>

        <div>
            <div class="view" style="height: 600px;">
                <img src=" assets/images/contact.jpg" class="img-fluid d-block w-100 h-100" alt="smaple image">
                <div class="mask flex-center container">
                    <div class="text-center w-75">
                        <p class="h1 fontsize70 text-white font-sm-h">Let's co-create the future</p>
                        <p class="h5 mt-4 text-white">Join a long list of satisfied clients, partners, and successful
                            businesses that we have had the pleasure of working with</p>
                        <div class="mt-4">
                            <button mdbBtn type="button" color="danger" class="btnWithHoverOnluborder" mdbWavesEffect
                                routerLink="/contactus">Get in Touch</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="container-fluid">
            <div class="row p-5">
                <div class="col-md-12 ">
                    <div class="row">
                        <div class="col-md-6 p-5 ">
                            <div class="ml-5">

                                <p class="h1 mb-4">{{SelectedService?.Set2.Title}}</p>

                                <div>
                                    <p class="h6 lineheight-p"> {{SelectedService?.Set2.Description}}
                                    </p>
                                </div>
                                <div>

                                    <ul class="list-group list-group-flush customlist">
                                        <li class="list-group-item d-flex" *ngFor="let item of SelectedService?.Set2.List">
                                            <mdb-icon far icon="check-square" class="mr-2 red-text"></mdb-icon>
                                            {{item.Item}}
                                        </li>

                                    </ul>
                                </div>
                                <div class="mt-4">
                                    <button mdbBtn type="button" color="danger" class="btnWithHoverOnluborder"
                                        mdbWavesEffect>Learn
                                        more</button>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6 p-5">
                            <img [src]="SelectedService?.Set2.Image" alt="no img" class="img-fluid mr-5" width="100%">
                        </div>

                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class=" grey lighten-5">
            <div class="container-fluid">
                <div class="row p-5">
                    <div class="col-md-12 ">
                        <div class="row justify-content-between">
                            <div class="col-md-2 py-4 text-center">
                                <img src="assets/Icons/download.png" alt="c3" class="img-fluid">
                            </div>
                            <div class="col-md-2 py-4 text-center">
                                <img src="assets/Icons/download.png" alt="c3" class="img-fluid">
                            </div>
                            <div class="col-md-2 py-4 text-center">
                                <img src="assets/Icons/download.png" alt="c3" class="img-fluid">
                            </div>
                            <div class="col-md-2 py-4 text-center">
                                <img src="assets/Icons/download.png" alt="c3" class="img-fluid">
                            </div>
                            <div class="col-md-2 py-4 text-center">
                                <img src="assets/Icons/download.png" alt="c3" class="img-fluid">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div> -->

    </div>



    <div>
        <app-footer></app-footer>
    </div>
</section>