<footer class="page-footer font-small pt-4 navbar-light grey lighten-5">

  <div class="container-fluid text-center text-md-left px-5 py-4">
    <div class="row">
      <div class="col-md-3 mt-md-0 mt-3">
        <a routerLink="/" class="">
          <img src="assets/images/logonew.png" alt="no img" width="200px" class="ml-3 mb-4">
        </a>
        <ul class="text-lg-left list-unstyled ml-4 black-text">
          <li>
            <p class="h5">
              <mdb-icon fas icon="map-marked" class="pr-2"></mdb-icon>Address :
            </p>
            <h3 class="mb-1"><strong><u>INDIA-Corporate Office</u>:</strong></h3>
            <h4>E-Floor,Taj Deccan, Road No.1,
              <br>
              Banjara Hills, Hyderabad – 500034,
              <br>
              Telangana, India.
              <br>
              <strong>Phone :</strong> +91-9666633432
              <br>
            <strong>For Enquiry: </strong>info@syberzen.com
            <br>
          <strong> For Business:</strong> business@syberzen.com
            </h4>
           </li>
        </ul>
      </div>
      <hr class="clearfix w-100 d-md-none pb-3">

      <div class="col-md-9">
        <div class="row">
          <div class="col-md-4 mb-md-0 mb-3">
            <h3 class="text-uppercase red-text"> <strong>Products</strong></h3>
            <div>
              <ul class="list-group list-group-flush customlist ">
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text">Techademic</a>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text">Codeschool</a>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text">Pokejobs</a>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text">Vidtranslate</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- <hr class="clearfix w-100 d-md-none pb-3"> -->
          <div class="col-md-4 mb-md-0 mb-3">
            <h4 class="text-uppercase red-text"> <strong>Services</strong></h4>
            <div>
              <ul class="list-group list-group-flush customlist ">
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text" (click)="openService('1')">Digital Transformation</a>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text" (click)="openService('2')">Design thinking</a>
                </li>
                <!-- <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text">Intelligent Testing</a>
                </li> -->
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text" (click)="openService('4')">Product Engineering and Beyond</a>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text"  (click)="openService('5')">Artificial Intelligence</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- <hr class="clearfix w-100 d-md-none pb-3"> -->
          <div class="col-md-4 mb-md-0 mb-3">
            <h4 class="text-uppercase red-text"><strong> About</strong></h4>
            <div>
              <ul class="list-group list-group-flush customlist ">
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text" routerLink="/about">Company Overview </a>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text" routerLink="/about">Leadership</a>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text" routerLink="/about"> Corporate Social Responsibility</a>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0)" class="hoverUnderLine2 py-1 black-text" routerLink="/about">Gallery</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- <hr class="clearfix w-100 d-md-none pb-3"> -->
          
        </div>
      </div>


    </div>
  </div>
  
  <div class="footer-copyright text-center py-3 elegant-color-dark">© Copyright 2018 - 2021 | Syberzen Infosec Pvt Ltd | All Rights Reserved.
    <!-- <a href="https://syberzen.com/" class="red-text"> Syberzen.com</a> -->
  </div>

</footer>