<section>

    <div class="view" style="height: 100vh;">
        <img src="assets/images/work2.jpg" class="img-fluid w-100 h-100" alt="smaple image">
        <div class="mask flex-center rgba-black-strong">
            <div class="container  text-white">
                <p class="h1 header mt-5 mb-4 pt-4 font-sm-h"> Changing the world through digital <br> experiences. </p>
                <p class="headderContent h4 mb-5 w-75 font-sm-p">
                    We believe businesses need to build on their digital journey and leverage proven technologies using
                    AI powered Digital transformation, Artificial Intelligence Solutions, Product Engineering using
                    New-age technologies like NLP, Computer Vision, ML, Interactive Chatbot agents, Data Science and
                    platform driven services and solutions to become more competitive in today’s world. Today companies
                    needs help in leveraging massive data that is available at their disposal from multiple enterprise
                    systems to discover intelligence from them.
                </p>

            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-10 p-5">
                <p class="h2"> <span class="sideheadding sideheaddingScroll">About SyberzenLabs</span> </p>
                <br>
                <p class="h5">SyberzenLabs has been formed to help enterprises to leverage Artificial Intelligence, Design thinking, Digital transformation & Product Engineering, we have built
                    various solutions for our clients to cover most of these areas over the last 3 years. </p>
                <p class="h5">
                     Syberzen has merged business of Subrains Solutions Private Limited to strengthen our
                    capabilities and started SyberzenLabs as focus area in this space. SuBrains has also had tremendous
                    traction working with clients globally over the last 3 years and this merger has helped both the
                    entities to leverage each other strengths and deliver greater value to our customers.
                </p>
                <p class="h5">
                    SyberzenLabs has experienced team and patent pending products in AI space to help deliver value to
                    our customers. Multiple Proof-of-concepts have been built for user experience and to help clients
                    understand potential use cases to implement these technologies.
                </p>

                <div class="text-center mt-5" >
                    <a mdbBtn type="button" class="px-5" size="lg" color="danger"
                     mdbWavesEffect href="http://syberzenlabs.com/" target="_blank">Explore
                    more</a>
                </div>
               
            </div>

            <!-- <div class="col-md-11">
                <div class="row pb-5">
                    <div class="col-md-8">
                        <div class="view">
                            <img src="assets/images/118576-thumb.jpg" class="img-fluid" alt="smaple image">
                            <div class="mask flex-center">
                                <div class="container text-white w-50 ml-5">
                                    <p class="h4"> Started </p>
                                    <br>
                                    <p class="headderContent h6">
                                        We believe everybody has a story to tell. Adobe Creative Cloud unleashes
                                        creativity so anyone — from the most demanding professional to students just
                                        starting out — can make whatever they want, wherever they want.
                                    </p>
                                    <div class="pt-3">
                                        <button mdbBtn type="button" size="sm" color="danger"
                                            class="btnWithHoverOnluborder" mdbWavesEffect>Learn
                                            more</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex-center p-5 blue-gradient text-white">
                            <p class="h2">The future belongs to those who create.</p>
                        </div>
                    </div>
                </div>
                <div class="row pb-5">
                    <div class="col-md-4">
                        <div class="flex-center p-5 purple-gradient text-white">
                            <p class="h2">In the experience economy, every business must be a digital business.</p>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="view">
                            <img src="assets/images/118576-thumb.jpg" class="img-fluid" alt="smaple image">
                            <div class="mask flex-center">
                                <div class="container text-white w-50 ml-5">
                                    <p class="h4"> Creativity for all. </p>
                                    <br>
                                    <p class="headderContent h6">
                                        We believe everybody has a story to tell. Adobe Creative Cloud unleashes
                                        creativity so anyone — from the most demanding professional to students just
                                        starting out — can make whatever they want, wherever they want.
                                    </p>
                                    <div class="pt-3">
                                        <button mdbBtn type="button" size="sm" color="danger"
                                            class="btnWithHoverOnluborder" mdbWavesEffect>Learn
                                            more</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pb-5">
                    <div class="col-md-8">
                        <div class="view">
                            <img src="assets/images/118576-thumb.jpg" class="img-fluid" alt="smaple image">
                            <div class="mask flex-center">
                                <div class="container text-white w-50 ml-5">
                                    <p class="h4"> Creativity for all. </p>
                                    <br>
                                    <p class="headderContent h6">
                                        We believe everybody has a story to tell. Adobe Creative Cloud unleashes
                                        creativity so anyone — from the most demanding professional to students just
                                        starting out — can make whatever they want, wherever they want.
                                    </p>
                                    <div class="pt-3">
                                        <button mdbBtn type="button" size="sm" color="danger"
                                            class="btnWithHoverOnluborder" mdbWavesEffect>Learn
                                            more</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="flex-center p-5 peach-gradient text-white">
                            <p class="h2">The future belongs to those who create.</p>
                        </div>
                    </div>
                </div>
                <div class="row pb-5">
                    <div class="col-md-12">
                        <div class="view">
                            <img src="assets/images/118576-thumb.jpg" class="img-fluid w-100" alt="smaple image">
                            <div class="mask flex-center">
                                <div class="container text-white w-50 ml-5">
                                    <p class="h4"> Creativity for all. </p>
                                    <br>
                                    <p class="headderContent h6">
                                        We believe everybody has a story to tell. Adobe Creative Cloud unleashes
                                        creativity so anyone — from the most demanding professional to students just
                                        starting out — can make whatever they want, wherever they want.
                                    </p>
                                    <div class="pt-3">
                                        <button mdbBtn type="button" size="sm" color="danger"
                                            class="btnWithHoverOnluborder" mdbWavesEffect>Learn
                                            more</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <!-- <div class="row grey lighten-4 py-5">
        <div class="col-md-12 py-5">
            <p class="text-center h2">Our values.</p>
        </div>
        <div class="col-md-12">
            <div class="container pb-5">
                <div class="row">
                    <div class="col-md-3">
                        <p class="h4">Genuine</p>
                        <h4>Sincere, trustworthy and reliable </h4>
                    </div>
                    <div class="col-md-3">
                        <p class="h4">Genuine</p>
                        <h4>Sincere, trustworthy and reliable </h4>
                    </div>
                    <div class="col-md-3">
                        <p class="h4">Genuine</p>
                        <h4>Sincere, trustworthy and reliable </h4>
                    </div>
                    <div class="col-md-3">
                        <p class="h4">Genuine</p>
                        <h4>Sincere, trustworthy and reliable </h4>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

</section>