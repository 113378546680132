import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-about-subrains',
  templateUrl: './about-subrains.component.html',
  styleUrls: ['./about-subrains.component.scss']
})
export class AboutSubrainsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(window).scroll(function () {
      $('.sideheaddingScroll').toggleClass('sideheadding-ShowUnderLine', $(this).scrollTop() > 550);
      $('.sideheaddingScroll2').toggleClass('sideheadding-ShowUnderLine', $(this).scrollTop() > 1850);
      $('.sideheaddingScroll3').toggleClass('sideheadding-ShowUnderLine', $(this).scrollTop() > 3137);
    });
  }

}
