import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  // ContactForm = {
  //   Name: '',
  //   Email: '',
  //   Phone: null,
  //   Company: '',
  //   Message: '',
  // }
  constructor() { }


  ngOnInit(): void {
  }
  // ContactUs() {
    // console.log("ContactForm", this.ContactForm);
  // }
}

