import { Component, OnInit } from '@angular/core';
import { CommonService } from '../Providers/common.service'
import { Router, NavigationExtras, ActivatedRoute, Data } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  ServiceId: any;
  SelectedService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public CommonService: CommonService
  ) {
    this.route.params.subscribe(param => {
      this.ServiceId = param.id.toString();
      console.log(this.ServiceId)
      if (this.ServiceId) {
        this.GetProducts();
      }
    })
  }

  ngOnInit(): void {
  }


  async GetProducts() {
    var url = 'assets/jsonfiles/services.json';
    this.CommonService.getMethod(url)
      .then((data: Data) => {
        console.log("==>", data);
 
        data.forEach(x => {
            if(x.Id == this.ServiceId){
              this.SelectedService = x;
            }
        });

        console.log("SelectedService::", this.SelectedService)

      })
  }





}
