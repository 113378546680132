<section>
    <!-- <div class="mainNavbar">
        <app-navbar></app-navbar>
    </div> -->
    <div class="mainNavbar2">
        <app-navbar></app-navbar>
    </div>

    <!-- <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark stylish-color sticky-top" class="subnavbar">
        <links class="mx-auto w-75">
            <a class="navbar-brand aboutsubrains" href="javascript:void(0)" routerLink="/about">About SyberzenLabs</a>
            <ul class="navbar-nav mr-auto mt-lg-0">
                <li class="nav-item hoverUnderLine" routerLinkActive="nav-active">
                    <a class="nav-link" href="javascript:void(0)" routerLink="/about/news-room"  >Newsroom </a>
                </li>
                <li class="nav-item hoverUnderLine" routerLinkActive="nav-active">
                    <a class="nav-link" href="javascript:void(0)" routerLink="/about/corporate-social-responcibility">Corporate Social Responcibility</a>
                </li>
                <!-- <li class="nav-item hoverUnderLine" routerLinkActive="nav-active">
                    <a class="nav-link" href="javascript:void(0)" routerLink="/about/leaders">Leaders</a>
                </li>
             
                <li class="nav-item hoverUnderLine" routerLinkActive="nav-active">
                    <a class="nav-link" href="javascript:void(0)" routerLink="/about/gallaery">Gallaery</a>
                </li>

            </ul>
        </links>
    </mdb-navbar> -->

    <div>
        <router-outlet></router-outlet>
    </div>

    <div>
        <app-footer></app-footer>
    </div>
</section>