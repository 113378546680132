import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-products-solutions-header',
  templateUrl: './products-solutions-header.component.html',
  styleUrls: ['./products-solutions-header.component.scss']
})
export class ProductsSolutionsHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    $('.wrap').ready(()=>{
      const slideElements = ['.back__slide', '.card__slide', '.content__slide'];
      let inProgress = false;
  
      const goToSlide = (slideElements, index) => {
        if (!inProgress) {
          inProgress = true;
  
          $('.card_active').addClass('exit');
          $('.card_active').removeClass('card_active');
          slideElements.forEach(elem => {
            $(`${elem}:nth-child(${index})`).addClass('card_active');
          });
  
          const evenSlide = index % 2 === 0;
          if (evenSlide)
            $('.content__ping').addClass('content__ping--right'); else
  
            $('.content__ping').removeClass('content__ping--right');
          $('.content__ping--noanimation').removeClass('content__ping--noanimation');
  
          setTimeout(() => $('.exit').removeClass('exit'), 3000);
          setTimeout(() => inProgress = false, 5000);
        }
      };
  
      $('.content__slide:nth-child(1) .button').on('click', () => goToSlide(slideElements, 2));
      $('.content__slide:nth-child(2) .button').on('click', () => goToSlide(slideElements, 1));
  
      setTimeout(() => goToSlide(slideElements, 2), 5000);
      setTimeout(() => goToSlide(slideElements, 1), 15000);
  
  
    })

  }

}
