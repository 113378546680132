import { Component, OnInit } from '@angular/core';
declare var $: any;
import { RellaxDirective } from '../directives/rellax.directive'
import * as Rellax from 'rellax';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor() { 
    var rellax = new Rellax('.rellax')
      //Disable vertical Parallax Scrolling     vertical:false
  }


  cards = [
    {
      title: 'Card Title 1',
      description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    },
    {
      title: 'Card Title 2',
      description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    },
    {
      title: 'Card Title 3',
      description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    },
    {
      title: 'Card Title 4',
      description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    },
    {
      title: 'Card Title 5',
      description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    },
    {
      title: 'Card Title 6',
      description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    },
    {
      title: 'Card Title 7',
      description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    },
    {
      title: 'Card Title 8',
      description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    },
    // {
    //   title: 'Card Title 9',
    //   description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    //   buttonText: 'Button',
    //   img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    // },
  ];
  slides: any = [[]];
  chunk(arr: any, chunkSize: any) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }


  ngOnInit(): void {

    $(window).scroll(function () {
      $('.mainNavbar').toggleClass('scrolled', $(this).scrollTop() > 50);
      $('.mainNavbar').toggleClass('d-block', $(this).scrollTop() < 50);
      $('.mainNavbar').toggleClass('d-none', $(this).scrollTop() > 50);

      $('.aboutsubrains').toggleClass('aboutsubrainsblack', $(this).scrollTop() > 50);
      $('.subnavbar nav').toggleClass('navbar-light white', $(this).scrollTop() > 50);
      $('.subnavbar nav').toggleClass('navbar-dark stylish-color', $(this).scrollTop() < 50);
    });

    $(document).ready(function () {
      var $window = $(window);
      $('section[data-type="background"]').each(function () {
        var $bgobj = $(this); // assigning the object
        $(window).scroll(function () {
          var yPos = -($window.scrollTop() / $bgobj.data('speed'));
          var coords = '50% ' + yPos + 'px';
          $bgobj.css({ backgroundPosition: coords });
        });
      });
    });

    this.slides = this.chunk(this.cards, 3);

  }


}
