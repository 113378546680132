import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

// import { ServicespageComponent } from './AllServices/services/services.component'
import { AboutUsAllComponent } from './About/about-us-all/about-us-all.component'
import { AboutSubrainsComponent } from './About/about-subrains/about-subrains.component';
import { NewsroomComponent } from './About/newsroom/newsroom.component';
import { CorporateSocialResponcibilityComponent } from './About/corporate-social-responcibility/corporate-social-responcibility.component';
import { LeadersComponent } from './About/leaders/leaders.component';
import { GallaeryComponent } from './About/gallaery/gallaery.component'
import { ProductsComponent } from './products/products.component'
import { ProductsSolutionsComponent } from './products-solutions/products-solutions.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  // { path: 'services', component: ServicesComponent },
  { path: 'contactus', component: ContactUsComponent },
  { path: 'services/:id', component: ServicesComponent },
  {
    path: 'about', component: AboutUsAllComponent,
    children: [
      {
        path: '', component: AboutSubrainsComponent,
      },
      {
        path: 'news-room', component: NewsroomComponent,
      },
      {
        path: 'corporate-social-responcibility', component: CorporateSocialResponcibilityComponent,
      },
      {
        path: 'leaders', component: LeadersComponent,
      },
      {
        path: 'gallaery', component: GallaeryComponent,
      }
    ]
  },
  {
    path: 'products', component: ProductsComponent,
  },
  {
    path: 'products-solutions', component: ProductsSolutionsComponent,
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
