<mdb-navbar SideClass="navbar navbar-expand-lg  navbar-light white fixed-top" [containerInside]="true">
  <mdb-navbar-brand>

    <a routerLink="/" class="navbar-brand">
      <img src="assets/images/logonew.png" alt="no img" width="140px">
    </a>
  </mdb-navbar-brand>

  <links>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item  mx-3 hoverUnderLine" routerLinkActive="nav-active">
        <a class="nav-link waves-light" mdbWavesEffect routerLink="/products-solutions">PRODUCTS & SOLUTIONS</a>
      </li>
      <li class="nav-item dropdown  mx-3 hoverUnderLine custom-drop" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          SERVICES <span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light hoverUnderLine2" mdbWavesEffect href="javascript:void(0)"
            (click)="openService('1')">Digital
            Transformation</a>
          <a class="dropdown-item waves-light hoverUnderLine2" mdbWavesEffect href="javascript:void(0)"
            (click)="openService('2')">Design
            Thinking</a>
          <!-- <a class="dropdown-item waves-light hoverUnderLine2" mdbWavesEffect href="javascript:void(0)"
            (click)="openService('3')">Intelligent
                        Testing</a> -->
          <a class="dropdown-item waves-light hoverUnderLine2" mdbWavesEffect href="javascript:void(0)"
            (click)="openService('4')">Product Engineering
            and Beyond</a>

          <a class="dropdown-item waves-light hoverUnderLine2" mdbWavesEffect href="javascript:void(0)"
            (click)="openService('5')">Artificial 
            Intelligence</a>
        </div>
      </li>
      <li class="nav-item  mx-3 hoverUnderLine" routerLinkActive="nav-active">
        <a class="nav-link waves-light" mdbWavesEffect href="https://syberzen.com/" target="_blank">SYBERZEN</a>
      </li>
      <!-- <li class="nav-item  mx-3 dropdown custom-drop hoverUnderLine" routerLinkActive="nav-active">
        <a class="nav-link waves-light " mdbWavesEffect>SERVICES</a>

        <div class="dropdown-menu row z-depth-1 ">
         <div class="row justify-content-center">
            <div class="col-md-7 col-xl-9 mt-5 mb-4 ">
               <div class="row">
                <div class="col-md-4">
                  <h5 class="sub-title mb-4 font-weight-bold dark-grey-text">
                    <a class="drop-menu-items hoverUnderLine2" (click)="openService('1')"><strong>Digital
                        Transformation</strong></a>
                  </h5>
                </div>
                <div class="col-md-4">
                  <h5 class="sub-title mb-4 font-weight-bold dark-grey-text">
                    <a class="drop-menu-items hoverUnderLine2" (click)="openService('2')"><strong>Design
                        Thinking</strong></a>
                  </h5>
                </div>
                <div class="col-md-4">
                  <h5 class="sub-title mb-4 font-weight-bold dark-grey-text">
                    <a class="drop-menu-items hoverUnderLine2" (click)="openService('3')"><strong>Intelligent
                        Testing</strong></a>
                  </h5>
                </div>
                <div class="col-md-4">
                  <h5 class="sub-title mb-4 font-weight-bold dark-grey-text">
                    <a class="drop-menu-items hoverUnderLine2" (click)="openService('4')"><strong>Product Engineering
                        and Beyond</strong></a>
                  </h5>
                </div>
                <div class="col-md-4">
                  <h5 class="sub-title mb-4 font-weight-bold dark-grey-text">
                    <a class="drop-menu-items hoverUnderLine2" (click)="openService('5')"><strong>Linguistic
                        Intelligence</strong></a>
                  </h5>
                </div>

              </div>
            </div>
          </div>
        </div>
      </li>-->
      <li class="nav-item  mx-3 hoverUnderLine " routerLinkActive="nav-active">
        <a class="nav-link waves-light" mdbWavesEffect routerLink="/about" mdbDropdownToggle>ABOUT</a>
      </li>
      <!-- <li class="nav-item  mx-3 hoverUnderLine">
        <a class="nav-link waves-light" mdbWavesEffect>OURWORK</a>
      </li> -->
      <li class="nav-item  mx-3 hoverUnderLine" routerLinkActive="nav-active">
        <a class="nav-link waves-light" mdbWavesEffect routerLink="/contactus">CONTACT</a>
      </li>
    </ul>
  </links>

</mdb-navbar>